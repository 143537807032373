/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
import humanizeDuration from 'humanize-duration';
import htmlToText from 'html-to-text';
import { get } from 'lodash';
import VideoSeriesIcon from '~images/icons/video-series-icon.svg';
import VideoIcon from '~images/icons/video-icon.svg';
import AudioIcon from '~images/icons/audio-icon.svg';
import { localeDate, makeIso8601DurationFromSeconds } from './dates';

export const boolFlagFromString = flag => flag === 'Yes';

export const calculateVideoContentType = (isSingleVideo, isAudio) => {
  if (isAudio) {
    return 'Podcast';
  }
  if (isSingleVideo) {
    return 'Video';
  }
  return 'Series';
};

export const stringFromUserAccessLevel = planId => {
  switch (planId) {
    case null:
      return 'Free User';
    case 1:
      return 'Member';
    case 2:
      return 'Premium';
    case 3:
      return 'Education';
    case 4:
      return 'Producers Circle';
    default:
      return 'Guest';
  }
};

export const planIdFromString = plan => {
  switch (plan) {
    case 'Free':
      return 0;
    case 'Member':
      return 1;
    case 'Premium':
      return 2;
    case 'Educator':
      return 3;

    default:
      return 0;
  }
};

export const calculateAppropriateCtaVideo = (
  isAdmin,
  adminCtaVideo,
  userSubscriptionLevelCtaVideo,
  everyoneCtaVideo
) => {
  if (isAdmin && adminCtaVideo) {
    return adminCtaVideo;
  }

  if (userSubscriptionLevelCtaVideo) {
    return userSubscriptionLevelCtaVideo;
  }

  if (everyoneCtaVideo) {
    return everyoneCtaVideo;
  }
  return null;
};

export const calcDurationOfAllVideos = videos => {
  const startValue = 0;
  const durationInSeconds = videos.reduce(
    (accumulator, currentValue) => accumulator + currentValue.length_in_seconds,
    startValue
  );
  const durationInMs = durationInSeconds * 1000;
  return humanizeDuration(durationInMs, {
    units: ['h', 'm'],
    round: true
  });
};

export const calcDurationOfAllVideosInSeconds = videos => {
  const startValue = 0;
  const durationInSeconds = videos.reduce(
    (accumulator, currentValue) => accumulator + currentValue.length_in_seconds,
    startValue
  );
  return durationInSeconds;
};

export const calcDurationOfFirstVideo = videoLenghtInSeconds => {
  const videoLenghtInMs = videoLenghtInSeconds * 1000;
  return humanizeDuration(videoLenghtInMs, {
    units: ['h', 'm'],
    round: true
  });
};

export const formatDurationOfFirstVideoTishaBav = videoLenghtInSeconds => {
  const isLongerThanTwoHours = videoLenghtInSeconds >= 7200;
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => (isLongerThanTwoHours ? 'hours' : 'hour'),
        m: () => 'min',
        s: () => 's',
        ms: () => 'ms'
      }
    }
  });
  const videoLenghtInMs = videoLenghtInSeconds * 1000;
  return shortEnglishHumanizer(videoLenghtInMs, {
    units: ['h', 'm'],
    round: true
  });
};

export const calcDurationOfFirstVideoDisplayedShort = videoLenghtInSeconds => {
  const isLongerThanTwoHours = videoLenghtInSeconds >= 7200;
  const isShorterThanHour = videoLenghtInSeconds < 3600;
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => (isLongerThanTwoHours ? 'hours' : 'hour'),
        m: () => (isShorterThanHour ? 'minutes' : 'min'),
        s: () => 's',
        ms: () => 'ms'
      }
    }
  });
  const videoLenghtInMs = videoLenghtInSeconds * 1000;
  return shortEnglishHumanizer(videoLenghtInMs, {
    units: ['h', 'm'],
    round: true
  });
};

export const collectHomePagePlaylistIds = (unfurlData = {}) => {
  const homepageSlices = unfurlData.slices;
  if (!homepageSlices) {
    return [];
  }

  return homepageSlices.reduce((acc, curr) => {
    if (curr.slice_type === 'playlists') {
      curr.items.map(x => acc.push(x.playlist.id));
    }

    return acc;
  }, []);
};

export const collectPagePlaylistIds = (unfurlData = {}) => {
  const { slices } = unfurlData;
  if (!slices) {
    return [];
  }
  return slices
    .filter(slice => slice.slice_type === 'playlists')
    .map(slice => slice.items || [])
    .map(items =>
      items.reduce((ids, item) => (item.playlist ? [...ids, item.playlist.id] : ids), [])
    )
    .reduce((accumulator, element) => [...accumulator, ...element], []);
};

const removeHtmlTagsFromTranscript = transcript =>
  htmlToText.fromString(transcript, {
    hideLinkHrefIfSameAsText: true,
    ignoreHref: true
  });

export const createVideoSeoObject = (playlistSeo, videoSeo, video) => {
  const videoTitle = videoSeo?.seo_title || video?.title;
  return {
    ...playlistSeo,
    seo_title: `${playlistSeo?.seo_title} : ${videoTitle}`,
    seo_description: videoSeo?.seo_description || video?.description?.text,
    seo_image: videoSeo?.seo_image || video?.cover_image?.url || playlistSeo?.seo_image,
    seo_keywords: videoSeo?.seo_keywords || playlistSeo?.seo_keywords
  };
};

export const createStructuredDataObject = (video, authorData, seo) => {
  // All of the following properties are required for a video to be displayed on the website, so I assume it's safe to take for granted that they will not be null
  const name = video.title;
  const description = video.description.text;
  const duration = makeIso8601DurationFromSeconds(video.length_in_seconds);
  const thumbnailUrl = video.cover_image.url;
  const transcript = removeHtmlTagsFromTranscript(video.transcript.text);
  const uploadDate = localeDate(video.first_publication_date);
  const contentUrl = video.wistia_url.url;
  const thumbnail = {
    url: video.cover_image.url,
    width: '1920',
    height: '1080'
  };
  const author = {
    name: authorData.name
  };
  const keywords = seo;

  return {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name,
    description,
    duration,
    thumbnailUrl,
    transcript,
    uploadDate,
    contentUrl,
    thumbnail,
    author,
    keywords,
    IsFamilyFriendly: true
  };
};

export function LightenColor(color, percent) {
  let cColor = color.replace('#', '');
  const opacity = percent ? percent / 100 : 0;
  let final = '';
  let x;
  if (cColor.length < 6) {
    cColor = cColor[0] + cColor[0] + cColor[1] + cColor[1] + cColor[2] + cColor[2];
  }
  for (let i = 0; i < 3; i += 1) {
    x = parseInt(cColor.substr(i * 2, 2), 16);
    x = Math.round(Math.min(Math.max(0, x + x * opacity), 255)).toString(16);
    final += `00${x}`.substr(x.length);
  }

  return final;
}

export function sortCurationPageCards(type, data) {
  if (!type || type === 'default') return data;

  if (type === 'name') {
    const sortedPlaylists = [...data].sort((a, b) => {
      if (a.card_title < b.card_title) {
        return -1;
      }
      if (a.card_title > b.card_title) {
        return 1;
      }
      return 0;
    });
    return sortedPlaylists;
  }

  return [...data].sort((a, b) => {
    const a_playlistTotalTime = a.playlists.reduce((acc, current) => acc + current, 0);
    const b_playlistTotalTime = b.playlists.reduce((acc, current) => acc + current, 0);
    return b_playlistTotalTime - a_playlistTotalTime;
  });
}

export const getWatchDurationInMinutesFormatted = durationInSeconds => {
  const hours = Math.floor(durationInSeconds / (60 * 60));
  const minutes = Math.floor(durationInSeconds / 60) % 60;
  const seconds = durationInSeconds % 60;
  if (hours > 0) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds
    ).padStart(2, '0')}`;
  }
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const getResourceTypeByRelativeUrl = url => {
  if (!url) {
    return '';
  }
  if (url.startsWith('/playlist/')) {
    return 'playlist';
  }
  if (url.startsWith('/podcasts/')) {
    return 'podcast';
  }
  return '';
};

export const PLAYLIST_TYPES = {
  AUDIO: 'Audio',
  VIDEO: 'Video',
  VIDEO_SERIES: 'Video series'
};

export const getPlaylistType = (videoCount, isAudio) => {
  if (isAudio) {
    return PLAYLIST_TYPES.AUDIO;
  }
  if (videoCount > 1) {
    return PLAYLIST_TYPES.VIDEO_SERIES;
  }
  return PLAYLIST_TYPES.VIDEO;
};

export const getPlaylistMetaText = (videos, resourceType) => {
  const duration =
    videos &&
    videos.length > 0 &&
    formatDurationOfFirstVideoTishaBav(get(videos, '[0].video.document.data.length_in_seconds', 0));

  const part = videos?.length > 1 ? `Part 1 of ${videos?.length}` : '';

  return [resourceType, part, duration].filter(Boolean).join(' • ');
};

export const getPlaylistCardReadableVideoDuration = videoLenghtInSeconds => {
  const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'hr',
        m: () => 'min',
        s: () => 's',
        ms: () => 'ms'
      }
    }
  });
  const videoLenghtInMs = videoLenghtInSeconds * 1000;
  return shortEnglishHumanizer(videoLenghtInMs, {
    units: ['h', 'm'],
    round: true,
    conjunction: ' & '
  });
};

export const getPlaylistTypeIcon = playlistType => {
  if (playlistType === PLAYLIST_TYPES.AUDIO) {
    return AudioIcon;
  }
  if (playlistType === PLAYLIST_TYPES.VIDEO_SERIES) {
    return VideoSeriesIcon;
  }
  return VideoIcon;
};

export const getPlaylistAuthorShortName = authorName => {
  const nameParts = String(authorName || '')
    .trim()
    .split(' ');
  if (nameParts.length > 2) {
    return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
  }
  return authorName;
};

export const getCardFromPlaylist = playlist => ({
  id: playlist.uid,
  title: playlist.title,
  image: playlist.cover_image?.url,
  subtitle: playlist.author?.document?.data?.name || '',
  link: playlist.url,
  type: getPlaylistType(get(playlist, 'videos', []).length, playlist?.label_is_audio === 'Yes'),
  duration: getPlaylistCardReadableVideoDuration(
    get(playlist, 'videos[0].video.document.data.length_in_seconds', 0)
  ),
  part: playlist?.videos?.length > 1 ? `Part 1 of ${playlist?.videos?.length}` : ''
});
