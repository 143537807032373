import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import Divider from '~components/Divider';
import HtmlExcerpt from '~components/HtmlExcerpt';
import openHtmlAsPdf from '~utils/open-html-as-pdf';
import checkTranscriptAccessAndExpand from '~utils/transcript-access';
import { pushDataLayerEvent } from '~utils/data-layer';
import { useTestId } from '~utils/set-testid';

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    float: 'right'
  },
  loadingSpinner: {
    float: 'right',
    marginRight: '24px'
  },
  iconStyle: {
    marginRight: theme.spacing()
  }
}));

const Transcript = ({
  transcript,
  uid,
  hasUserSufficientAccess,
  handleInsufficientAccess,
  title,
  CustomReadMoreBtn,
  customReadMoreProps,
  isAudioTranscript
}) => {
  const classes = useStyles();
  const { session } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const testId = useTestId('download-transcript-button');

  const handleClick = async () => {
    if (!hasUserSufficientAccess) {
      handleInsufficientAccess();
      return;
    }
    setIsLoading(true);

    const url = window.location.href;

    const transcriptType = isAudioTranscript ? 'audio_transcript' : 'playlist_transcript';
    const popupIdFromError = await openHtmlAsPdf(transcriptType, {
      playlistTitle: title,
      url,
      videoSlug: uid
    });

    if (popupIdFromError) {
      handleInsufficientAccess(popupIdFromError);
    } else {
      pushDataLayerEvent('transcript_download_button_clicked');
    }

    setIsLoading(false);
  };

  return (
    <Box my={2} width="100%">
      <Typography variant="h6" component="h3">
        Transcript
        {isLoading ? (
          <CircularProgress className={classes.loadingSpinner} size={30} />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleClick}
            className={classes.buttonStyle}
            {...testId}
          >
            <PictureAsPdfIcon aria-hidden={false} className={classes.iconStyle} /> Print
          </Button>
        )}
      </Typography>

      <HtmlExcerpt
        html={transcript}
        textAlign="justify"
        videoUid={uid}
        session={session}
        checkAccessAndTrackClick={checkTranscriptAccessAndExpand}
        hasUserSufficientAccess={hasUserSufficientAccess}
        handleInsufficientAccess={handleInsufficientAccess}
        CustomReadMoreBtn={CustomReadMoreBtn}
        customReadMoreProps={customReadMoreProps}
      />
      <Divider />
    </Box>
  );
};

Transcript.propTypes = {
  transcript: PropTypes.string.isRequired,
  uid: PropTypes.string,
  hasUserSufficientAccess: PropTypes.bool.isRequired,
  handleInsufficientAccess: PropTypes.func.isRequired,

  title: PropTypes.string.isRequired,

  CustomReadMoreBtn: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.exact(undefined)
  ]),
  customReadMoreProps: PropTypes.shape({}),
  isAudioTranscript: PropTypes.bool
};

Transcript.defaultProps = {
  uid: null,
  isAudioTranscript: false,
  CustomReadMoreBtn: undefined,
  customReadMoreProps: {}
};

export default Transcript;
