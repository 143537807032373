import request from '~utils/request';
import { GET_PDF } from '~src/hooks/useApi';
import { POPUPS_MAP } from '~src/layout/Popups';

export default async function openHtmlAsPdf(type, data) {
  try {
    const { data: responseData } = await request(GET_PDF(type, data));

    // eslint-disable-next-line no-undef
    const file = new Blob([responseData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    const pdfWindow = window.open('about:blank', 'PDF');
    if (pdfWindow) {
      // if we couldn't open the new window (for example because of a popup blocker)
      pdfWindow.location.href = fileURL;
    } else {
      // then open in the same window
      window.location.href = fileURL;
    }

    return null;
  } catch (err) {
    const { status } = err.response;

    switch (status) {
      case 401:
        return POPUPS_MAP.UPGRADE_TO_PREMIUM_FOR_FEATURE;
      case 403:
        return POPUPS_MAP.TRANSCRIPTS_LIMIT;
      case 500:
        return POPUPS_MAP.PDF_TOO_LARGE_FOR_DOWNLOAD;
      default:
        return POPUPS_MAP.PDF_TOO_LARGE_FOR_DOWNLOAD;
    }
  }
}
