import { extractKnownSlices, extractSeoData } from '~utils/unfurl-helpers';
import { get } from '../utils/get';

const formatDateRange = (start, end) => (start && end ? `${start} - ${end}` : start);

const extractFeaturedPlaylist = prismicData => {
  const currentParshatSlices = get(
    prismicData,
    'prismicWeeklyTorahPortion.data.current.document.data.slices',
    null
  );

  const featuredPlaylistFromCurrentParshat = currentParshatSlices.find(
    slice => slice.slice_type === 'featured_playlist'
  );
  if (featuredPlaylistFromCurrentParshat) {
    if (get(featuredPlaylistFromCurrentParshat, 'primary.featured_playlist.url', null) === null) {
      return null;
    }
    return {
      url: featuredPlaylistFromCurrentParshat.primary.featured_playlist.url,
      uid: featuredPlaylistFromCurrentParshat.primary.featured_playlist.document.uid,
      ...featuredPlaylistFromCurrentParshat.primary.featured_playlist.document.data,
      videos: get(
        featuredPlaylistFromCurrentParshat,
        'primary.featured_playlist.document.data.videos',
        []
      ).map(video => get(video, 'video.document.data'))
    };
  }

  const featuredPlaylistFromHero = get(
    prismicData,
    'prismicWeeklyTorahPortion.data.hero_featured_playlist.document',
    null
  );

  if (!featuredPlaylistFromHero) {
    return null;
  }

  return {
    url: get(featuredPlaylistFromHero, 'url', ''),
    uid: get(featuredPlaylistFromHero, 'uid', ''),
    is_audio: get(featuredPlaylistFromHero, 'data.label_is_audio', '') === 'Yes',
    ...get(featuredPlaylistFromHero, 'data')
  };
};

const extractPlaylistData = slices => {
  const playlistsSlices = slices.filter(slice => slice.slice_type === 'playlists');
  const playlists = get(playlistsSlices, '[0].items', [])
    .filter(({ playlist }) => !!playlist.document)
    .map(({ playlist }) => ({
      ...playlist.document.data,
      url: playlist.url,
      uid: playlist.uid,
      videos: get(playlist, 'document.data.videos', []).map(video =>
        get(video, 'video.document.data')
      ),
      is_audio: get(playlist, 'document.data.label_is_audio', '') === 'Yes',
      author: get(playlist, 'document.data.author.document.data.name')
    }));
  return playlists;
};

const extractGuidesData = slices => {
  const resourcesSlices = slices.filter(slice => slice.slice_type === 'resources');
  const guides = get(resourcesSlices, '[0].items', [])
    // NOTE: elements in 'items' have to be consistent with the `ResourceItem` component props
    .map(({ resource }) => ({
      uid: resource.uid,
      url: resource.url,
      // eslint-disable-next-line no-underscore-dangle
      material_type: resource.document.__typename,
      ...get(resource, 'document.data', {
        description: '',
        title: resource.url,
        material_type: 'Web Link'
      })
    }));
  return guides;
};

const extractCurrentData = prismicData => {
  const data = get(prismicData, 'prismicWeeklyTorahPortion.data.current.document.data');
  const { start_date, end_date, slices } = data;

  const extracted = {
    title: data.title,
    date: formatDateRange(start_date, end_date),
    verses: data.verses,
    guides: extractGuidesData(slices),
    playlists: extractPlaylistData(slices)
  };

  return extracted;
};

const correctOrderOfData = data => {
  const parshaOrder = ['Genesis', 'Exodus', 'Leviticus', 'Numbers', 'Deuteronomy'];
  return data.sort((a, b) => parshaOrder.indexOf(a.data.title) - parshaOrder.indexOf(b.data.title));
};

export const extractListOfEvents = prismicData => {
  const data = get(prismicData, 'allPrismicBook.nodes');
  const correctedData = correctOrderOfData(data);
  const events = [];
  correctedData.forEach(entry => {
    get(entry, 'data.chapters').forEach(({ chapter }) => {
      const { start_date, end_date } = chapter.document.data;
      events.push({
        ...chapter.document.data,
        url: chapter.url,
        uid: chapter.uid,
        category: get(entry, 'data.title'),
        description: get(chapter, 'document.data.slices[0].primary.description'),
        date: formatDateRange(start_date, end_date)
      });
    });
  });

  return events;
};

export const extractCurrentParshaName = prismicData => prismicData.prismicParshat.data.title;

export default prismicData => ({
  current: extractCurrentData(prismicData),
  listOfEvents: extractListOfEvents(prismicData),
  featuredPlaylist: extractFeaturedPlaylist(prismicData),
  seo: extractSeoData(prismicData, 'prismicWeeklyTorahPortion'),
  slices: extractKnownSlices(prismicData, 'prismicWeeklyTorahPortion.data.body')
});
