import isBot from 'isbot';
import { isIOS } from 'react-device-detect';
import { REDIRECT_DATA } from '~layout/MobileAppBlocker/MobileAppBlockerPopup';
import { getNavigationPageLink } from '~utils/common-site-helper';
import ExperimentTracking from './experiment-tracking';

export const AB_EXPERIMENTS = {
  PUSH_TO_APP: {
    NAME: 'Push to App strategy',
    VARIANTS: {
      CONTROL: 'control',
      NO_PUSH: 'no-push',
      FORCE_PUSH: 'force-push'
    },
    IS_CROSS_DOMAIN: true
  },
  VIDEO_PLAYLIST: {
    NAME: 'Marketing Playlist Experiment',
    VARIANTS: {
      CONTROL: 'control',
      NO_BANNER: 'no-banner',
      PROMINENT_PLAY_BUTTON: 'prominent-play-button'
    }
  }
};

export const setPushToAppExperiment = isSubscriber => {
  const { userAgent } = typeof window !== 'undefined' ? window.navigator : {};
  // only iOS users
  const isTargetAudience = !isSubscriber && isIOS && !isBot(userAgent);

  if (isTargetAudience) {
    ExperimentTracking.trackAndGetVariant(
      AB_EXPERIMENTS.PUSH_TO_APP.NAME,
      Object.values(AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS),
      AB_EXPERIMENTS.PUSH_TO_APP.IS_CROSS_DOMAIN,
      [AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.CONTROL] // Control variant should not be assigned
    );
  }
};

export const getPushToAppExperimentCtaData = () => {
  const isTargetAudience = isIOS;

  // default is set to NO PUSH (for non ios users)
  const variant = isTargetAudience
    ? ExperimentTracking.getVariant(
        AB_EXPERIMENTS.PUSH_TO_APP.NAME,
        AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.NO_PUSH
      )
    : AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.NO_PUSH;

  switch (variant) {
    case AB_EXPERIMENTS.PUSH_TO_APP.VARIANTS.FORCE_PUSH: {
      const pushUrl = isIOS ? REDIRECT_DATA.iOSLink : REDIRECT_DATA.androidLink;
      return { text: 'Start Free Trial on App', url: pushUrl };
    }
    default:
      return { text: 'Start your free trial', url: getNavigationPageLink('/subscribe', true) };
  }
};
