/* eslint-disable no-case-declarations */
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import React from 'react';
import { Element } from 'react-scroll';
import { Resources, ResourceItem } from '~components/slices/resources';
import { TextBlockHTML, TextBlockColsHTML } from '~components/slices/text-block';
import FeaturedVideo from '~components/slices/featured-video';
import RecommendedVideosGallery from '~components/slices/recommended-videos-gallery';
import AuthorTestimonials from '~components/slices/author-testimonials';
import {
  extractVideoGalleryPlaylists,
  extractPlaylists,
  extractFeaturedPlaylist,
  extractResources,
  extractAuthorTestimonials,
  makeLinkTargetNameFromTitle,
  extractVideoList,
  extractAboutHeroData,
  makeNavlinks,
  extractHeroTextImageData,
  extractFounderData,
  extractAboutPageAboutUsData,
  extractAboutPageCtaData,
  extractAboutPageTestimonialsData,
  extractAboutPageVideoPathData,
  extractAboutPageTeamData,
  extractAboutPageFaqData,
  extractFaqSectionsData,
  extractAudioSectionData,
  extractTeamSection,
  extractMaterialsForExpansionPanel,
  extractAbLabsIntroData,
  extractSpotlightPlaylists,
  extractLearningMethods,
  extractOfferSectionData,
  extractIntroductionData,
  extractWaysToLearnData,
  extractShowcaseLibrary,
  extractArticleToc,
  extractMinimalVideoList,
  extractTestimonials,
  extractLandingFaqs,
  extractArticleResourceCards
} from '~utils/unfurl-helpers';
import { calculateHolidayHero } from '~src/utils/holiday-helpers';
import { get } from '~src/prismic/utils/get';
import { JobsSection, TeamSection } from '~src/modules/team-page';
import { MaterialsExpansionPanel as MaterialsAccordion } from '~src/components/playlist';
import { ProducersCircleMeetTheProducersSection } from '~src/modules/producers-circle';
import PlaylistContinueWatching from '../playlists/playlist-continue-watching';

const RichText = loadable(() => import('~components/RichText'));
const Playlists = loadable(() => import('~components/slices/playlists'));
const AboutHero = loadable(() => import('~src/components/slices/about-hero'));
const HeroTextImage = loadable(() => import('~components/slices/hero-text-image'));
const HeroTextToc = loadable(() => import('~components/slices/hero-text-toc'));
const CourseHero = loadable(() => import('~components/slices/course-hero'));
const Container = loadable(() => import('~components/Container'));
const VideoList = loadable(() => import('~components/VideoList'));
const MarketingLandingVideoList = loadable(() => import('~components/MarketingLandingVideoList'));
const MinimalVideoList = loadable(() => import('~components/slices/minimal-video-list'));
const AboutVideos = loadable(() => import('~modules/about-page/components/AboutVideos'));
const AboutUs = loadable(() => import('~modules/about-page/components/AboutUs'));
const AboutFaq = loadable(() => import('~modules/about-page/components/AboutFaq'));
const AboutTeam = loadable(() => import('~modules/about-page/components/AboutTeam'));
const AboutTestimonials = loadable(() =>
  import('~modules/about-page/components/AboutTestimonials')
);
const AboutCta = loadable(() => import('~modules/about-page/components/AboutCta'));
const AudioFileSection = loadable(() => import('~components/slices/audio-file-section'));
const FaqSections = loadable(() => import('~components/slices/faq-sections'));
const FaqHero = loadable(() => import('~components/slices/faq-hero'));
const EmailAcquisitionLine = loadable(() => import('~components/slices/email-acquisition-line'));
const PromotionalBox = loadable(() => import('~components/slices/promotional-box'));
const AbLabsIntro = loadable(() => import('~components/slices/ab-labs-intro'));
const SpotlightPlaylists = loadable(() => import('~components/slices/spotlight-playlists'));
const LearningMethods = loadable(() => import('~components/slices/learning-methods'));
const CourseIntroduction = loadable(() => import('~components/slices/course-introduction'));
const CourseAuthor = loadable(() => import('~components/slices/course-author'));
const CoursePerks = loadable(() => import('~components/slices/course-perks'));
const CourseCta = loadable(() => import('~components/slices/course-cta'));
const FeaturedCourse = loadable(() => import('~components/slices/featured-course'));
const LetterHero = loadable(() => import('~components/slices/letter-hero'));
const CtaButtonSection = loadable(() => import('~components/slices/cta-button-section'));
const CouponComponents = loadable(() => import('~modules/holiday/components/CouponsComponent'));
const ArticleHero = loadable(() => import('~components/slices/article-hero'));
const ArticleContent = loadable(() => import('~components/slices/article-content'));
const ArticleContentImage = loadable(() => import('~components/slices/article-content-image'));
const ArticleEmailCapture = loadable(() => import('~components/slices/article-email-capture'));
const ArticleResources = loadable(() => import('~components/slices/article-resources'));
const ArticleRelatedPages = loadable(() => import('~components/slices/article-related-pages'));
const ArticleFaq = loadable(() => import('~components/slices/article-faq'));
const ArticleContentBox = loadable(() => import('~components/slices/article-content-box'));
const ArticleCard = loadable(() => import('~components/slices/article-card'));
const ArticleDiscussionCard = loadable(() => import('~components/slices/article-discussion-card'));
const ArticleInlineAd = loadable(() => import('~components/slices/article-inline-ad'));
const ArticleTrendingList = loadable(() => import('~components/slices/article-trending-list'));
const AboutAlephBeta = loadable(() => import('~components/AboutBoxSlice'));
const VideoOffer = loadable(() => import('~components/VideoOffer'));
const Introduction = loadable(() => import('~modules/landing-page/Introduction'));
const WaysToLearn = loadable(() => import('~modules/landing-page/LearningMethods'));
const ShowcaseHero = loadable(() => import('~modules/landing-page/ShowcaseHero'));
const AnimationHero = loadable(() => import('~modules/landing-page/AnimationHero'));
const PreviewSpotlights = loadable(() => import('~modules/landing-page/PreviewSpotlights'));
const PreviewLibrary = loadable(() => import('~modules/landing-page/PreviewLibrary'));
const Testimonials = loadable(() => import('~modules/landing-page/Testimonials'));
const LandingFaq = loadable(() => import('~modules/landing-page/Faq'));
const LargeTextCta = loadable(() => import('~modules/landing-page/LargeTextCta'));
const LargeImageCta = loadable(() => import('~modules/landing-page/LargeImageCta'));
const FeatureMobileApp = loadable(() => import('~modules/landing-page/FeatureMobileApp'));
const TishaBavHero = loadable(() => import('~modules/tisha-bav-event/slices/TishaBavHero'));
const TishaBavDescription = loadable(() =>
  import('~modules/tisha-bav-event/slices/TishaBavDescription')
);

const FounderSection = loadable(() => import('../team-page-founder'));

function makeSliceToComponentConverter(progress, isCustomSpacingTextBlocks, isLoggedIn) {
  let sliceIndex = 0;
  return (
    slice,
    _,
    slices,
    listOfParshas,
    currentParsha,
    globalProps,
    pageBreadcrumbs,
    containerSize
  ) => {
    const { slice_type, primary } = slice;

    sliceIndex += 1;
    // NOTE: does not guarantee uniqueness BETWEEN renders!
    const fallbackUnsafeKey = `${slice_type}-${sliceIndex}`;
    switch (slice_type) {
      case 'continue_watching': {
        const shouldDisplayContinueWatching = isLoggedIn && primary.continue_watching_playlist;

        return shouldDisplayContinueWatching && <PlaylistContinueWatching theme={primary.theme} />;
      }
      case 'team_page_founder': {
        const founder = extractFounderData(slice);
        return <FounderSection key={fallbackUnsafeKey} {...founder} />;
      }
      case 'text_block': {
        const key = primary.text_block_title_rich_text.text || fallbackUnsafeKey;
        const name = makeLinkTargetNameFromTitle(primary.text_block_title_rich_text.text);
        return (
          <Element key={key} name={name} style={{ width: '100%' }}>
            <TextBlockHTML
              section_title_html={primary.text_block_title_rich_text.html}
              {...primary}
              isCustomSpacingTextBlocks={isCustomSpacingTextBlocks}
            />
          </Element>
        );
      }
      case 'text_block_cols': {
        const key = primary.text_block_title_rich_text.text || fallbackUnsafeKey;
        const name = makeLinkTargetNameFromTitle(primary.text_block_title_rich_text.text);
        return (
          <Element key={key} name={name}>
            <TextBlockColsHTML
              section_title_html={primary.text_block_title_rich_text.html}
              {...primary}
              isCustomSpacingTextBlocks={isCustomSpacingTextBlocks}
            />
          </Element>
        );
      }
      case 'featured_playlist': {
        const featuredPlaylistData = extractFeaturedPlaylist(slice);
        const key = featuredPlaylistData.uid || fallbackUnsafeKey;
        return <FeaturedVideo key={key} {...featuredPlaylistData} />;
      }
      case 'playlists': {
        const playlists = extractPlaylists(slice);
        const name = makeLinkTargetNameFromTitle(primary.section_title_rich_text.text);
        const key = primary.section_title_rich_text.text || fallbackUnsafeKey;
        return (
          <Element key={key} name={name}>
            <Playlists
              section_title={primary.section_title_rich_text.html}
              playlists={playlists}
              progress={progress}
            />
          </Element>
        );
      }
      case 'custom_html': {
        const key = primary.custom_html.text.slice(10) || fallbackUnsafeKey;
        return (
          <Container key={key}>
            <RichText html={primary.custom_html.text} />
          </Container>
        );
      }
      case 'author_testimonials': {
        const authorTestimonialsData = extractAuthorTestimonials(slice);
        const key = authorTestimonialsData.name || fallbackUnsafeKey;
        return <AuthorTestimonials key={key} {...authorTestimonialsData} />;
      }
      case 'video_gallery': {
        const videoGalleryData = extractVideoGalleryPlaylists(slice);
        const key = get(videoGalleryData, 'section_title.html', null) || fallbackUnsafeKey;
        return <RecommendedVideosGallery key={key} {...videoGalleryData} />;
      }
      case 'resources': {
        const resources = extractResources(slice);
        const name = makeLinkTargetNameFromTitle(primary.section_title_rich_text.text);
        const key = primary.section_title_rich_text.text || fallbackUnsafeKey;
        return (
          <Element key={key} name={name}>
            <Resources title={primary.section_title_rich_text.html}>
              {resources.map(item => (
                <ResourceItem key={item.url} {...item} />
              ))}
            </Resources>
          </Element>
        );
      }
      case 'promotional_box':
        return <PromotionalBox key={fallbackUnsafeKey} {...primary} />;

      case 'one_line_email_acquisition':
        return <EmailAcquisitionLine key={fallbackUnsafeKey} {...primary} />;

      case 'video_list': {
        const playlists = extractVideoList(slice);
        const key = get(slice, 'primary.title_video_list.text', null) || fallbackUnsafeKey;
        return (
          <VideoList
            key={key}
            playlists={playlists}
            sectionTitle={primary.title_video_list}
            sectionSubtitle={primary.event_date}
            theme={primary.theme}
            compactMargins={!!primary.compact_margins}
          />
        );
      }
      case 'minimal_video_list': {
        const items = get(slice, 'items', []);
        const playlist_items = extractMinimalVideoList(items);
        const bottom_shape_divider = get(slice, 'primary.video_list_bottom_divider', null);
        const key = get(slice, 'primary.listtitle.text', null) || fallbackUnsafeKey;
        return (
          <MinimalVideoList
            key={key}
            items={playlist_items}
            title={primary.listtitle.text}
            bottom_shape_divider={bottom_shape_divider}
          />
        );
      }
      case 'marketing_video_list': {
        const playlists = extractVideoList(slice);
        const key = get(slice, 'id', null) || fallbackUnsafeKey;
        return <MarketingLandingVideoList key={key} {...primary} playlists={playlists} />;
      }
      case 'about_hero': {
        const hero = extractAboutHeroData(slice);
        const key = get(hero, 'hero_text.html', null) || fallbackUnsafeKey;
        return <AboutHero key={key} {...hero} />;
      }
      case 'hero_text-toc': {
        const hero = primary;
        const navlinks = makeNavlinks(slices);
        const key = hero.title || fallbackUnsafeKey;
        return (
          <HeroTextToc
            key={key}
            {...hero}
            navlinks={navlinks}
            listOfParshas={listOfParshas}
            currentParshaName={currentParsha}
          />
        );
      }
      case 'hero_text-image': {
        const hero = extractHeroTextImageData(slice);
        const key = get(hero, 'title.html', null) || fallbackUnsafeKey;
        return <HeroTextImage key={key} {...hero} />;
      }
      case 'faq_hero': {
        const hero = primary;
        const key = get(hero, 'hero_title.html', null) || fallbackUnsafeKey;
        return <FaqHero key={key} {...hero} />;
      }
      case 'about_us':
      case 'about_page_about_us': {
        const about_us = extractAboutPageAboutUsData(slice);
        return <AboutUs key={fallbackUnsafeKey} {...about_us} />;
      }
      case 'about_testimonials': {
        const testimonials = extractAboutPageTestimonialsData(slice);
        return <AboutTestimonials key={fallbackUnsafeKey} {...testimonials} />;
      }
      case 'about_videos_path': {
        const get_started = extractAboutPageVideoPathData(slice);
        return <AboutVideos key={fallbackUnsafeKey} {...get_started} />;
      }
      case 'about_team': {
        const team = extractAboutPageTeamData(slice);
        return <AboutTeam key={fallbackUnsafeKey} {...team} />;
      }
      case 'about_faq': {
        const faq = extractAboutPageFaqData(slice);
        return <AboutFaq key={fallbackUnsafeKey} {...faq} />;
      }
      case 'about_cta': {
        const cta = extractAboutPageCtaData(slice);
        return <AboutCta key={fallbackUnsafeKey} {...cta} />;
      }
      // FIXME: Somehow rename the obsolete `audio_file_` slice name in the FAQ page custom Prismic type definition
      case 'audio_file_section':
      case 'audio_file_': {
        const audio_section = extractAudioSectionData(slice);
        const key = audio_section.title || fallbackUnsafeKey;
        return <AudioFileSection key={key} {...audio_section} />;
      }
      // NOTE: the `'faq_sections_collapsed'` is a custom-made slice type not existing in Prismic!
      case 'faq_sections_collapsed': {
        const { sections } = slice;
        const key = get(sections, '[0].section_title.text', null) || fallbackUnsafeKey;
        return <FaqSections key={key} faqSections={sections} />;
      }
      case 'team_section': {
        const section = extractTeamSection(slice);
        const key = get(section, 'section_title.html', null) || fallbackUnsafeKey;
        return <TeamSection {...section} key={key} />;
      }
      case 'job_section': {
        const jobs = primary;
        const key = fallbackUnsafeKey;
        return <JobsSection key={key} {...jobs} />;
      }
      case 'expansion_panel': {
        const data = extractMaterialsForExpansionPanel(slice);
        const key = data.panel_title || fallbackUnsafeKey;
        return (
          <div style={{ width: '100%' }} key={key}>
            <MaterialsAccordion title={data.panel_title} materials={data.materials} />
          </div>
        );
      }
      case 'producers_circle_members': {
        const producersCircleMembers = get(slice, 'items', []);
        const key = fallbackUnsafeKey;
        return (
          <ProducersCircleMeetTheProducersSection
            key={key}
            producersCircleMembers={producersCircleMembers}
          />
        );
      }
      case 'ab_labs_intro': {
        const data = extractAbLabsIntroData(slice);
        const key = String(get(data, 'content.html', fallbackUnsafeKey)).slice(0, 100);
        return <AbLabsIntro key={key} {...data} />;
      }
      case 'spotlight_playlists': {
        const data = extractSpotlightPlaylists(slice);
        const key = data?.key || fallbackUnsafeKey;
        return <SpotlightPlaylists key={key} {...data} containerSize={containerSize} />;
      }
      case 'offer': {
        const data = extractOfferSectionData(slice);
        const key = `${get(data, 'id', fallbackUnsafeKey)}`;
        return <CouponComponents key={key} {...primary} />;
      }
      case 'course_hero': {
        const hero = get(slice, 'primary', null);
        const key = get(slice, 'id', '') || fallbackUnsafeKey;
        return <CourseHero key={key} {...globalProps} {...hero} />;
      }
      case 'learning_methods': {
        const data = extractLearningMethods(slice);
        const key = data?.key || fallbackUnsafeKey;
        return <LearningMethods key={key} {...data} />;
      }
      case 'course_introduction': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <CourseIntroduction key={key} {...globalProps} {...data} />;
      }
      case 'course_author': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <CourseAuthor key={key} {...data} />;
      }
      case 'course_perks': {
        const data = get(slice, 'primary', null);
        const perks = get(slice, 'items', []);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <CoursePerks key={key} {...data} perks={perks} />;
      }
      case 'course_cta': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <CourseCta key={key} {...globalProps} {...data} />;
      }
      case 'featured_course': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <FeaturedCourse key={key} {...data} />;
      }
      case 'letter_hero': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <LetterHero key={key} {...data} />;
      }
      case 'cta_button': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <CtaButtonSection key={key} {...data} />;
      }
      case 'article_hero': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleHero key={key} {...data} />;
      }
      case 'article_content': {
        const data = get(slice, 'primary', null);
        const contentBlocks = get(slice, 'items', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        const toc = extractArticleToc(slices);
        return (
          <ArticleContent
            key={key}
            contentBlocks={contentBlocks}
            {...data}
            pageBreadcrumbs={pageBreadcrumbs}
            toc={toc}
            containerSize={containerSize}
          />
        );
      }
      case 'article_image': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleContentImage key={key} {...data} containerSize={containerSize} />;
      }
      case 'article_faq': {
        const data = get(slice, 'primary', null);
        const faqs = get(slice, 'items', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleFaq key={key} faqs={faqs} {...data} containerSize={containerSize} />;
      }
      case 'article_content_box': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleContentBox key={key} {...data} containerSize={containerSize} />;
      }
      case 'article_email_capture': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleEmailCapture key={key} {...data} containerSize={containerSize} />;
      }
      case 'article_resources': {
        const data = get(slice, 'primary', null);
        const items = extractArticleResourceCards(get(slice, 'items', []));
        const key = get(slice, 'id', fallbackUnsafeKey);
        return (
          <ArticleResources key={key} {...data} resources={items} containerSize={containerSize} />
        );
      }
      case 'article_related_pages': {
        const data = get(slice, 'primary', null);
        const items = get(slice, 'items', []);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return (
          <ArticleRelatedPages key={key} {...data} pages={items} containerSize={containerSize} />
        );
      }
      case 'article_card': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleCard key={key} {...data} containerSize={containerSize} />;
      }
      case 'article_discussion_points': {
        const data = get(slice, 'primary', null);
        const points = extractArticleResourceCards(get(slice, 'items', []));
        const key = get(slice, 'id', fallbackUnsafeKey);
        return (
          <ArticleDiscussionCard
            key={key}
            {...data}
            points={points}
            containerSize={containerSize}
          />
        );
      }
      case 'article_inline_ad': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleInlineAd key={key} {...data} containerSize={containerSize} />;
      }
      case 'about_aleph_beta': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        return (
          <Container key={key}>
            <AboutAlephBeta />
          </Container>
        );
      }
      case 'marketing_introduction': {
        const data = extractIntroductionData(slice);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <Introduction key={key} {...data} />;
      }
      case 'marketing_ways_to_learn': {
        const data = extractWaysToLearnData(slice);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <WaysToLearn key={key} {...data} />;
      }
      case 'showcase_hero': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ShowcaseHero key={key} {...data} />;
      }
      case 'animation_hero': {
        const data = get(slice, 'primary', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <AnimationHero key={key} {...data} />;
      }
      case 'preview_spotlights': {
        const data = get(slice, 'primary', null);
        const tabs = get(slice, 'items', null);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <PreviewSpotlights key={key} {...data} tabs={tabs} />;
      }
      case 'showcase_library': {
        const data = extractShowcaseLibrary(slice);
        const subtitles = get(slice, 'items', []).map(item => item.subtitle);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <PreviewLibrary key={key} {...data} subtitles={subtitles} />;
      }
      case 'video_offer': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        return <VideoOffer key={key} {...data} />;
      }
      case 'testimonials': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        const testimonials = extractTestimonials(get(slice, 'items', []));
        return <Testimonials key={key} {...data} testimonials={testimonials} />;
      }
      case 'landing_page_faq': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        const faqs = extractLandingFaqs(get(slice, 'items', []));
        return <LandingFaq key={key} {...data} items={faqs} />;
      }
      case 'large_text_cta': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        return <LargeTextCta key={key} {...data} />;
      }
      case 'large_image_cta': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        return <LargeImageCta key={key} {...data} />;
      }
      case 'feature_mobile_app': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        const data = get(slice, 'primary', null);
        return <FeatureMobileApp key={key} {...data} />;
      }
      case 'tb_event_hero': {
        const data = get(slice, 'primary', null);
        const list = get(slice, 'items', []);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <TishaBavHero key={key} {...globalProps} {...data} list={list} />;
      }
      case 'tb_description': {
        const data = get(slice, 'primary', null);
        const list = get(slice, 'items', []);
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <TishaBavDescription key={key} {...globalProps} {...data} list={list} />;
      }
      case 'trending_list': {
        const key = get(slice, 'id', fallbackUnsafeKey);
        return <ArticleTrendingList key={key} {...globalProps} />;
      }
      default:
        return null;
    }
  };
}

const Slices = ({
  slices,
  progress,
  listOfParshas,
  currentParsha,
  isCustomSpacingTextBlocks,
  isAdmin,
  isSubscriber,
  isUserStateLoading,
  isLoggedIn,
  globalProps,
  pageBreadcrumbs,
  containerSize
}) => {
  if (!slices?.length) {
    return null;
  }

  const holidayHeros = [];
  let normalSlices = [];
  slices.forEach(slice => {
    if (slice.slice_type === 'holiday_hero_split_screen') {
      holidayHeros.push(slice);
    } else {
      normalSlices.push(slice);
    }
  });
  const holidayHero = calculateHolidayHero(isAdmin, isSubscriber, isUserStateLoading, holidayHeros);

  const convertSliceToComponent = makeSliceToComponentConverter(
    progress,
    isCustomSpacingTextBlocks,
    isLoggedIn
  );

  const faqSlices = normalSlices.filter(slice =>
    ['faq_section', 'faq_sections'].includes(slice.slice_type)
  );
  if (faqSlices.length) {
    const collapsedFaqSectionsSlice = {
      slice_type: 'faq_sections_collapsed',
      sections: extractFaqSectionsData(faqSlices)
    };
    // FIXME: In the FAQ custom Prismic type the slice is called `faq_sections` (plural) for historical reasons. Rename it somehow, sometime.
    const firstFaqSectionPosition = normalSlices.findIndex(slice =>
      ['faq_sections', 'faq_section'].includes(slice.slice_type)
    );
    normalSlices = normalSlices.filter(
      slice => !['faq_sections', 'faq_section'].includes(slice.slice_type)
    );
    normalSlices.splice(firstFaqSectionPosition, 0, collapsedFaqSectionsSlice);
  }

  return (
    <>
      {holidayHero}
      {normalSlices.map((slice, _, arrOfSlices) =>
        convertSliceToComponent(
          slice,
          _,
          arrOfSlices,
          listOfParshas,
          currentParsha,
          globalProps,
          pageBreadcrumbs,
          containerSize
        )
      )}
    </>
  );
};

Slices.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.shape({})),
  progress: PropTypes.shape({}),
  listOfParshas: PropTypes.arrayOf(PropTypes.shape({})),
  currentParsha: PropTypes.string,
  isCustomSpacingTextBlocks: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSubscriber: PropTypes.bool,
  isUserStateLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  globalProps: PropTypes.shape({}),
  pageBreadcrumbs: PropTypes.shape({
    currentTitle: PropTypes.string,
    parentUrl: PropTypes.string,
    parentTitle: PropTypes.string,
    parentType: PropTypes.string
  }),
  containerSize: PropTypes.string
};
Slices.defaultProps = {
  slices: [],
  progress: {},
  listOfParshas: null,
  currentParsha: null,
  isCustomSpacingTextBlocks: false,
  isAdmin: false,
  isSubscriber: false,
  isUserStateLoading: false,
  isLoggedIn: false,
  globalProps: null,
  pageBreadcrumbs: {},
  containerSize: null
};
export default Slices;
