import { navigate } from 'gatsby';

import request from '~utils/request';
import { trackFullTranscript } from '~utils/analytics';
import { pushDataLayerEvent } from '~utils/data-layer';
import {
  getGuestTranscriptRedirectProps,
  getInsufficientTranscriptAccessRedirectProps
} from '~utils/required-subscription-plan';
import { USER_TRANSCRIPT_ACCESS } from '~hooks/useApi';

const guestRedirectProps = getGuestTranscriptRedirectProps();
const insufficientTranscriptAccessRedirectProps = getInsufficientTranscriptAccessRedirectProps();

const checkTranscriptAccess = videoUid => {
  const params = USER_TRANSCRIPT_ACCESS({ video_slug: videoUid });
  return request(params);
};

export default async (videoUid, session, setIsTextExpanded) => {
  const { isLoggedIn, isSubscriber, isAdmin } = session;

  if (!isLoggedIn) {
    navigate('/subscribe', guestRedirectProps);
    return;
  }
  if (isSubscriber || isAdmin) {
    pushDataLayerEvent('transcript_expansion_button_clicked');
    trackFullTranscript(videoUid);

    setIsTextExpanded(prevIsTextExpanded => !prevIsTextExpanded);
    return;
  }

  try {
    const checkTranscriptAccessResult = await checkTranscriptAccess(videoUid);

    const { data } = checkTranscriptAccessResult;
    const { hasAccess } = data;

    if (hasAccess) {
      pushDataLayerEvent('transcript_expansion_button_clicked');
      trackFullTranscript(videoUid);
      setIsTextExpanded(prevIsTextExpanded => !prevIsTextExpanded);
    } else {
      navigate('/subscribe', insufficientTranscriptAccessRedirectProps);
    }
  } catch (error) {
    console.error(error);
  }
};
