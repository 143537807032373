import VideoSeriesIcon from '~images/icons/video-series-icon.svg';
import VideoIcon from '~images/icons/video-icon.svg';
import AudioIcon from '~images/icons/audio-icon.svg';
import GuideIcon from '~images/icons/guide-icon.svg';

export const RESOURCE_TYPES = {
  AUDIO: 'Audio',
  VIDEO: 'Video',
  VIDEO_SERIES: 'Video series',
  GUIDE: 'Guide',
  ARTICLE: 'Article',
  PRINTABLE_GUIDE: 'Printable Guide'
};

export const getResourceTypeIcon = resourceType => {
  switch (resourceType) {
    case RESOURCE_TYPES.AUDIO:
      return AudioIcon;
    case RESOURCE_TYPES.GUIDE:
    case RESOURCE_TYPES.PRINTABLE_GUIDE:
    case RESOURCE_TYPES.ARTICLE:
      return GuideIcon;
    case RESOURCE_TYPES.VIDEO_SERIES:
      return VideoSeriesIcon;
    case RESOURCE_TYPES.VIDEO:
    default:
      return VideoIcon;
  }
};
