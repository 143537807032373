import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import clsx from '~styles/clsx';
import AnalyticsTracking from '~utils/analytics-tracking';
import { nanoid } from 'nanoid';

const getSizeStyles = (size, theme) => {
  switch (size) {
    case 'large':
      return {
        fontSize: 26,
        padding: '24px 80px',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
          fontFamily: 'Inter',
          padding: '22px 58px',
          fontSize: 18
        }
      };
    case 'mini':
      return {
        fontSize: 16,
        padding: '12px 32px',
        [theme.breakpoints.down('xs')]: {
          fontFamily: 'Inter',
          padding: '8px 15px'
        }
      };
    case 'small':
    default:
      return {
        fontSize: 16,
        padding: '20px 48px',
        [theme.breakpoints.down('xs')]: {
          fontFamily: 'Inter',
          fontSize: 16,
          padding: '16px 34px'
        }
      };
  }
};

const getVariantStyles = variant => {
  switch (variant) {
    case 'black':
      return {
        color: '#FFFFFF',
        backgroundColor: '#272727',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#161616'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#000000'
        }
      };
    case 'blue': {
      return {
        color: '#fff',
        backgroundColor: '#278AE7',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#1080E9'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#0677E1'
        }
      };
    }
    case 'white-borderd': {
      return {
        color: '#FFFFFF',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
        '&:hover': {
          color: '#E6E6E6',
          textDecoration: 'none',
          boxShadow: 'inset 0px 0px 0px 2px #E6E6E6'
        },
        '&:active': {
          color: '#C9C9C9',
          textDecoration: 'none',
          boxShadow: 'inset 0px 0px 0px 2px #C9C9C9'
        }
      };
    }
    case 'white':
    default:
      return {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#F8F8F8'
        },
        '&:active': {
          textDecoration: 'none',
          backgroundColor: '#EFEFEF'
        }
      };
  }
};

const useStyles = makeStyles(theme => ({
  button: props => ({
    display: 'block',
    width: 'fit-content',
    fontWeight: 500,
    borderRadius: 40,
    whiteSpace: 'nowrap',
    zIndex: 10,
    ...getSizeStyles(props.size, theme),
    ...getVariantStyles(props.variant, props.border)
  })
}));

const RoundedLinkButton = ({
  text,
  to,
  size,
  variant,
  externalClassName,
  shouldTrackAnalytics,
  analyticsTitle,
  analyticsEvent,
  analyticsSection,
  id,
  ...rest
}) => {
  const classes = useStyles({ size, variant });
  const elementId = useMemo(() => nanoid() || id, [id]);

  useEffect(() => {
    if (elementId && shouldTrackAnalytics && analyticsEvent) {
      AnalyticsTracking.makeLinksTrackable(`#${elementId}`, analyticsEvent, {
        'Link Text': text,
        'Referrer Title': analyticsTitle,
        'Referrer Section': analyticsSection,
        'Page Path': typeof window !== 'undefined' && window.location.pathname
      });
    }
  }, [elementId, shouldTrackAnalytics, analyticsTitle, analyticsEvent, analyticsSection, text]);

  return (
    <Link id={elementId} to={to} className={clsx(classes.button, externalClassName)} {...rest}>
      {text}
    </Link>
  );
};

RoundedLinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string,
  shouldTrackAnalytics: PropTypes.bool,
  analyticsTitle: PropTypes.string,
  analyticsEvent: PropTypes.string,
  analyticsSection: PropTypes.string,
  size: PropTypes.oneOf(['mini', 'small', 'large']),
  variant: PropTypes.oneOf(['black', 'white', 'blue', 'purple', 'white-borderd']),
  externalClassName: PropTypes.string
};

RoundedLinkButton.defaultProps = {
  size: 'small',
  variant: 'dark',
  shouldTrackAnalytics: false,
  analyticsEvent: '',
  analyticsTitle: '',
  analyticsSection: '',
  id: '',
  externalClassName: ''
};

export default RoundedLinkButton;
