// AUTH
export const SYNC = () => ({
  url: `/users/sync?t=${new Date().getTime()}`
});

export const LOGIN = ({ username, password }) => ({
  url: '/auth/login/local',
  method: 'post',
  data: {
    username,
    password
  }
});

export const CHECK_USERNAME_EXISTS = ({ username }) => ({
  url: '/auth/check-username-exists',
  method: 'post',
  data: {
    username
  }
});

export const LOGIN_FB = ({ accessToken }) => ({
  url: '/users/register/social/facebook',
  method: 'post',
  data: {
    provider: 'facebook',
    // NOTE: Name `access_token` is important because it'll be passed verbatim to Passport.js and then to FB login machinery which expects this exact name.
    access_token: accessToken
  }
});

export const LOGOUT = () => ({
  url: '/auth/logout',
  method: 'post'
});

export const MAGIC_LINK_GET = ({ username }) => ({
  url: '/auth/magic-link',
  method: 'post',
  data: {
    username
  }
});

export const MAGIC_LINK_USE = ({ username, token }) => ({
  url: `/auth/magic-link/${username}/${token}`
});

export const VERIFY_EMAIL = ({ username, token }) => ({
  url: `/users/verify/${username}/${token}`
});

// USERS
export const REGISTER = ({
  fbEventId,
  username,
  password,
  meta_first_name,
  meta_last_name,
  ...rest
}) => ({
  url: '/users/register/local',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    username,
    password,
    meta_first_name,
    meta_last_name,
    ...rest
  }
});

export function USER_TRANSCRIPT_ACCESS({ video_slug }) {
  return {
    url: '/users/transcript-access',
    method: 'post',
    data: {
      video_slug
    }
  };
}

export const REGISTER_CTA = ({ fbEventId, username, ...rest }) => ({
  url: '/users/register/cta',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    username,
    ...rest
  }
});

export const REGISTER_UNEMPLOYED = ({
  fbEventId,
  username,
  meta_first_name,
  meta_last_name,
  most_recent_employer,
  meta_zip,
  last_date_of_employment,
  is_currently_unemployed
}) => ({
  url: '/users/register/unemployed-promo',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    username,
    meta_first_name,
    meta_last_name,
    most_recent_employer,
    meta_zip,
    last_date_of_employment,
    is_currently_unemployed
  }
});

export const REGISTER_SUPPORT_IN_NEED = ({ fbEventId, formPayload }) => ({
  url: '/users/register/support-in-need-promo',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    ...formPayload
  }
});
// SUBSCRIBE
export const SUBSCRIBE_STRIPE = ({ fbEventId, planId, planPeriod, coupon, card, ...rest }) => ({
  url: '/stripe/subscribe',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    planId,
    planPeriod,
    coupon,
    card,
    ...rest
  }
});
export const RECURRING_DONATION_STRIPE = ({ fbEventId, planId, planPeriod, card, ...rest }) => ({
  url: '/stripe/recurring-donation',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    planId,
    planPeriod,
    card,
    ...rest
  }
});

export const PURCHASE_BM_STRIPE = ({ card, isGift }) => ({
  url: '/stripe/buy-bar-mitzvah-package',
  method: 'post',
  data: {
    card,
    isGift
  }
});

export const UPDATE_STRIPE_CARD = ({ newSourceToken }) => ({
  url: '/stripe/card/update',
  method: 'post',
  data: {
    newSourceToken
  }
});

export function SUBSCRIBE_PAYPAL({ fbEventId, planId, planPeriod, ...rest }) {
  return {
    url: '/paypal/get-billing-agreement-url',
    method: 'post',
    headers: {
      'X-Facebook-Event-ID': fbEventId
    },
    data: {
      planId,
      planPeriod,
      ...rest
    }
  };
}

export function RECURRING_DONATION_PAYPAL({ fbEventId, planId, planPeriod, ...rest }) {
  return {
    url: '/paypal/get-recurring-donation-billing-agreement-url',
    method: 'post',
    headers: {
      'X-Facebook-Event-ID': fbEventId
    },
    data: {
      planId,
      planPeriod,
      ...rest
    }
  };
}

export function PURCHASE_BM_PAYPAL({ ...rest }) {
  return {
    url: '/paypal/buy-bar-mitzvah-package',
    method: 'post',
    data: {
      ...rest
    }
  };
}

export function CANCEL_STRIPE() {
  return {
    url: '/stripe/cancel-subscription',
    method: 'post'
  };
}

export function CANCEL_PAYPAL() {
  return {
    url: '/paypal/cancel-subscription',
    method: 'post'
  };
}

// COUPON
export function COUPON_GET({ coupon }) {
  const couponCode = encodeURIComponent(coupon);
  return {
    url: `/stripe/coupons/${couponCode}`
  };
}

// DONATE
export const DONATE = ({
  fbEventId,
  username,
  provider,
  amount,
  perkName = undefined,
  isDeliveryRequired = undefined,
  isSponsorship = undefined,
  sponsorshipProject = undefined,
  isAnonymous = undefined,
  ...rest
}) => ({
  url: '/donations/donate',
  method: 'post',
  headers: {
    'X-Facebook-Event-ID': fbEventId
  },
  data: {
    username,
    provider,
    amount,
    perkName,
    isDeliveryRequired,
    isSponsorship,
    sponsorshipProject,
    isAnonymous,
    ...rest
  }
});

// VIDEO
export function VIDEO_READY({ videoId }) {
  return {
    url: `/timers/config/${videoId}`
  };
}

export function SPONSORSHIP() {
  return {
    url: `/donations/sponsorship`
  };
}
export function VIDEO_PLAY({ videoId, playlistId, playlistUid, giftToken }) {
  return {
    url: '/timers/start',
    method: 'post',
    data: {
      videoId,
      playlistId,
      playlistSlug: playlistUid,
      giftToken
    }
  };
}

export function VIDEO_PROGRESS({ playlistId, playlistUid, videoId, progress, giftToken }) {
  return {
    url: '/timers/progress',
    method: 'post',
    data: {
      playlistId,
      playlistSlug: playlistUid,
      videoId,
      progress,
      giftToken
    }
  };
}

export function VIDEO_WATCHED({ playlistId, videoId }) {
  return {
    url: '/timers/watched',
    method: 'post',
    data: {
      playlistId,
      videoId
    }
  };
}

export function GET_CONTINUE_WATCHING() {
  return {
    url: '/content/continue-watching'
  };
}

export function SOFT_SALE_CLOSE({ which }) {
  return {
    url: `/timers/soft-sale/close/${which}`
  };
}

// COMMENTS
export function COMMENTS_GET({ playlistId, videoId }) {
  return {
    url: `/comments/get/${playlistId}/${videoId}`
  };
}

export function COMMENTS_ADD({ playlistId, playlistUid, videoId, threadId, comment }) {
  return {
    url: '/comments/add',
    method: 'post',
    data: {
      playlist_id: playlistId,
      playlistSlug: playlistUid,
      video_id: videoId,
      thread_id: threadId,
      comment
    }
  };
}

export function COMMENTS_EDIT({ commentId, playlistUid, comment }) {
  return {
    url: `/comments/edit/${commentId}`,
    method: 'post',
    data: {
      comment,
      playlistSlug: playlistUid
    }
  };
}

export function COMMENTS_DELETE({ commentId }) {
  return {
    url: `/comments/delete/${commentId}`,
    method: 'post'
  };
}

export function COMMENTS_REPORT({ commentId }) {
  return {
    url: `/comments/report/${commentId}`,
    method: 'post'
  };
}

// ONBOARDING
export function ONBOARDING_STEP_ONE({ topics }) {
  return {
    url: '/analytics/onboarding/email-preferences',
    method: 'post',
    data: {
      topics
    }
  };
}

export function ONBOARDING_STEP_TWO({ topics }) {
  return {
    url: '/analytics/onboarding/interests',
    method: 'post',
    data: {
      topics
    }
  };
}

export function ONBOARDING_STEP_THREE({ source }) {
  return {
    url: '/analytics/onboarding/source',
    method: 'post',
    data: {
      source
    }
  };
}

export function ONBOARDING_STEP_FOUR({ phone }) {
  return {
    url: '/analytics/onboarding/get-app-sms',
    method: 'post',
    data: {
      phone
    }
  };
}

// Account
export function ACCOUNT_UPDATE_PROFILE({
  meta_first_name,
  meta_last_name,
  meta_age_range,
  meta_gender,
  meta_phone,
  meta_address,
  meta_zip,
  meta_city,
  meta_country,
  meta_tz,
  education_school_name
}) {
  return {
    url: `/users/update`,
    method: 'post',
    data: {
      meta_first_name,
      meta_last_name,
      meta_age_range,
      meta_gender,
      meta_phone,
      meta_address,
      meta_zip,
      meta_city,
      meta_country,
      meta_tz,
      education_school_name
    }
  };
}

export function ACCOUNT_UPDATE_EMAIL({ current_password, new_username }) {
  return {
    url: `/users/update/email`,
    method: 'post',
    data: {
      current_password,
      new_username
    }
  };
}

export function ACCOUNT_UPDATE_PASSWORD({ new_password }) {
  return {
    url: `/users/update/password`,
    method: 'post',
    data: {
      new_password
    }
  };
}

export function EDUCATION_GET_STUDENTS() {
  return {
    url: `/users/students`
  };
}

export function EDUCATION_ADD_STUDENT({ username, meta_first_name, meta_last_name }) {
  return {
    url: `/users/register/student`,
    method: 'post',
    data: {
      username,
      meta_first_name,
      meta_last_name
    }
  };
}

// BAR BAT MITZVAH
export function BAR_MITZVAH_GET() {
  return {
    url: '/bar-mitzvah/content'
  };
}

export function ONBOARDING_BARMITZVAH({
  bm_date,
  bm_dob,
  bm_educational,
  bm_fullname,
  bm_identify,
  bm_parsha,
  bm_sidekick
}) {
  return {
    url: '/analytics/onboarding/bar-mitzvah',
    method: 'post',
    data: {
      bm_date,
      bm_dob,
      bm_educational,
      bm_fullname,
      bm_identify,
      bm_parsha,
      bm_sidekick
    }
  };
}

// ADMIN DASHBOARD
export function ADMIN_GET_DASHBOARD() {
  return {
    url: '/content/admin-dashboard'
  };
}

// ADMIN DASHBOARD - ACCOUNT DELETION REQUESTS
export function ADMIN_GET_ACCOUNT_DELETION_REQUESTS() {
  return {
    url: '/account-deletion-requests/list'
  };
}

// ADMIN USER EDIT FORM - FIND ACCOUNT DELETION REQUESTS
export function ADMIN_FIND_USER_ACCOUNT_DELETION_REQUEST({ userId }) {
  return {
    url: `/account-deletion-requests/${userId}`
  };
}

// ADMIN USER EDIT FORM - FULFILL ACCOUNT DELETION REQUESTS
export function ADMIN_FULFILL_USER_ACCOUNT_DELETION_REQUEST({ userId }) {
  return {
    url: `/account-deletion-requests/fulfill/`,
    method: 'post',
    data: { userId }
  };
}

// ADMIN USERS
export function ADMIN_USERS_WITH_BROKEN_SUBSCRIPTION({ page }) {
  return {
    url: `/users/list-broken-subscriptions?page=${page}`
  };
}
// ADMIN USERS
export function ADMIN_GET_USER_LIST({ search, page }) {
  return {
    url: `/users/list?search=${search}&page=${page}`
  };
}

export function ADMIN_GET_USER_LIST_ONE({ id }) {
  return {
    url: `/users/list/${id}`
  };
}

export function ADMIN_CREATE_USERS_BULK({ users }) {
  return {
    url: '/users/register/bulk',
    method: 'post',
    data: { users }
  };
}

export function ADMIN_CREATE_USER({ user }) {
  return {
    url: '/users/create',
    method: 'post',
    data: { ...user }
  };
}

export function ADMIN_UPDATE_USER({ user, id }) {
  return {
    url: `/users/update/${id}`,
    method: 'post',
    data: { ...user }
  };
}

export function ADMIN_DELETE_USER({ id }) {
  return {
    url: `/users/delete/${id}`,
    method: 'post'
  };
}

export function ADMIN_AUTO_SIGN_USER({ id }) {
  return {
    url: `/users/autosign/${id}`,
    method: 'post'
  };
}

export function ADMIN_UNLOCK_USER({ id }) {
  return {
    url: `/users/unlock/${id}`,
    method: 'post'
  };
}

export function ADMIN_VERIFY_USER_APPSTORE_RECEIPT({ id }) {
  return {
    url: `/appstore/force-verify-receipt`,
    method: 'post',
    data: { userId: id }
  };
}

// ADMIN COMMENTS
export function ADMIN_GET_ALL_COMMENTS({ search, page }) {
  return {
    url: `/comments/get-all?search=${search}&page=${page}`
  };
}

export function ADMIN_ADD_COMMENT({ playlistId, videoId, threadId, comment }) {
  return {
    url: `/comments/admin-add`,
    method: 'post',
    data: {
      playlist_id: playlistId,
      video_id: videoId,
      thread_id: threadId,
      comment
    }
  };
}
export function ADMIN_DELETE_COMMENT({ commentId }) {
  return {
    url: `/comments/admin-delete/${commentId}`,
    method: 'post'
  };
}

export function ADMIN_VIDEO_INFORMATION({ videoId }) {
  return {
    url: `/content/video-information/${videoId}`
  };
}

export function ADMIN_STATS({ queryId, startDate, endDate }) {
  return {
    url: `/users/stats`,
    method: 'post',
    data: {
      queryId,
      startDate,
      endDate
    }
  };
}

export function ADMIN_VIDEO_REPORT({ startDate, endDate }) {
  const query = new URLSearchParams();
  query.append('startDate', startDate);
  query.append('endDate', endDate);

  return {
    url: `/timers/stats?${query.toString()}`,
    data: {
      startDate,
      endDate
    }
  };
}

export function COHORT_STATS({ startDate, endDate }) {
  return {
    url: `/users/cohort`,
    method: 'post',
    data: {
      startDate,
      endDate
    }
  };
}

// ANALYTICS
export function ANALYTICS_LANDING_PAGEVIEW({ fbEventId, page, referrer, search, context }) {
  return {
    url: '/analytics/landing',
    method: 'post',
    headers: {
      'X-Facebook-Event-ID': fbEventId
    },
    data: {
      page,
      referrer,
      search,
      context: context || {} // backend requires context to be an object
    }
  };
}

export function ANALYTICS_PAGEVIEW({ fbEventId, page, referrer, search, context }) {
  return {
    url: '/analytics/pageview',
    method: 'post',
    headers: {
      'X-Facebook-Event-ID': fbEventId
    },
    data: {
      page,
      referrer,
      search,
      context: context || {} // backend requires context to be an object
    }
  };
}

export function ANALYTICS_EXPERIMENT_VARIANT({ experimentId, variant }) {
  return {
    url: '/analytics/experiment-variant',
    method: 'post',
    data: {
      experimentId,
      variant
    }
  };
}

export function ANALYTICS_CANCELLATION_INTENT({ decision }) {
  return {
    url: '/analytics/subscription/cancellation/intent',
    method: 'post',
    data: {
      decision
    }
  };
}

export function ANALYTICS_TRANSCRIPT_EXPANSION({ video_slug }) {
  return {
    url: '/analytics/full-transcript',
    method: 'post',
    data: {
      video_slug
    }
  };
}

export function ANALYTICS_MATERIALS_DOWNLOAD({ slug, filename, type }) {
  return {
    url: '/analytics/materials-download',
    method: 'post',
    data: {
      slug,
      filename,
      type
    }
  };
}

export function ANALYTICS_PRODUCERS_CIRCLE_EVENT_CLICK({ event_link, event_title, event_date }) {
  return {
    url: '/analytics/pc-event-click',
    method: 'post',
    data: {
      event_link,
      event_title,
      event_date
    }
  };
}

export function ANALYTICS_PRODUCERS_CIRCLE_MATERIAl_DOWNLOAD({ material_link, material_title }) {
  return {
    url: '/analytics/pc-material-download',
    method: 'post',
    data: {
      material_link,
      material_title
    }
  };
}

export function ANALYTICS_GET_USER_EVENTS({ from, to, userId }) {
  return {
    url: `/analytics/user_events/${userId}?from=${from}&to=${to}`
  };
}

export function ANALYTICS_ANTI_CHURN_SCREEN_BUTTON_CLICKED({ button }) {
  return {
    url: '/analytics/anti-churn-screen',
    method: 'post',
    data: {
      button
    }
  };
}

export function GET_PLAYLISTS_PROGRESS({ playlistIds }) {
  const query = playlistIds.join(',');
  return {
    url: `/timers/watching-progress-full?playlistIds=${query}`
  };
}

export function SEARCH_BY_TERM({ term }) {
  return {
    url: `/search?t=${encodeURIComponent(term)}`
  };
}

export function GET_RECOMMENDED_SEARCH_TERMS() {
  return {
    url: '/config'
  };
}

export function GET_TOP_100_SEARCHES() {
  return {
    url: '/search/top-100'
  };
}

export function IMPACT_PAGE_CARDS_DATA() {
  return {
    url: '/timers/impact/cards'
  };
}

export function IMPACT_PAGE_STATS_DATA() {
  return {
    url: '/timers/impact/stats'
  };
}

export function IMPACT_PAGE_TOP_DATA() {
  return {
    url: '/timers/impact/top'
  };
}

export function ANALYTICS_CURATION_PAGE_INTRO_VIDEO({ slug }) {
  return {
    url: '/analytics/curation-intro-video',
    method: 'post',
    data: {
      slug
    }
  };
}

export function ANALYTICS_CURATION_PAGE_SEARCH_TERM({ slug, searchTerm }) {
  return {
    url: '/analytics/curation-search-term',
    method: 'post',
    data: {
      slug,
      searchTerm
    }
  };
}

export function ANALYTICS_CURATION_PAGE_FILTER({ slug, sortingMethod }) {
  return {
    url: '/analytics/curation-filter',
    method: 'post',
    data: {
      slug,
      sortingMethod
    }
  };
}

export function ANALYTICS_CURATION_CARD_SELECT({ slug, card }) {
  return {
    url: '/analytics/curation-card',
    method: 'post',
    data: {
      slug,
      card
    }
  };
}

export function ANALYTICS_CURATION_CARD_SELECT_VIDEO({ slug, videoUid }) {
  return {
    url: '/analytics/curation-drawer-item',
    method: 'post',
    data: {
      slug,
      videoUid
    }
  };
}

export function ADMIN_CHECK_PREMIUM_USERS({ usernames }) {
  return {
    url: '/users/check-premium',
    method: 'post',
    data: {
      usernames
    }
  };
}

export function GIFT_PLAYLIST({
  playlistId,
  playlistSlug,
  message,
  gifterName,
  gifteeEmail,
  gifteeName,
  contentType,
  contentTitle,
  contentSubtitle,
  giftContentThumbnail
}) {
  return {
    url: '/gift/getAccessToken',
    method: 'post',
    data: {
      playlistId,
      playlistSlug,
      message,
      gifterName,
      gifteeEmail,
      gifteeName,
      contentType,
      contentTitle,
      contentSubtitle,
      giftContentThumbnail
    }
  };
}

export function GIFT_THANK_YOU_REPLY({
  playlistId,
  playlistSlug,
  sender,
  message,
  contentType,
  contentTitle,
  contentSubtitle,
  contentThumbnail,
  giftToken
}) {
  return {
    url: '/gift/giftThankYouReply',
    method: 'post',
    data: {
      playlistId,
      playlistSlug,
      sender,
      message,
      contentType,
      contentTitle,
      contentSubtitle,
      contentThumbnail,
      giftToken
    }
  };
}

export function SHARE_PLAYLIST({ method, url }) {
  return {
    url: '/analytics/event',
    method: 'post',
    data: {
      eventName: 'Share',
      eventProperties: {
        method,
        url
      }
    }
  };
}

export function USER_VIEW_HISTORY() {
  return {
    url: '/timers/history'
  };
}

export function GET_PDF(type, data) {
  return {
    url: `/users/pdf`,
    method: 'post',
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf'
    },
    params: {
      type
    },
    data
  };
}

export function PODCAST_START_LISTENING({ episode }) {
  return {
    url: '/analytics/event',
    method: 'post',
    data: {
      eventName: 'Podcast - Start Listening',
      eventProperties: {
        episode
      }
    }
  };
}

export function PODCAST_START_EPISODE({ episode }) {
  return {
    url: '/analytics/event',
    method: 'post',
    data: {
      eventName: 'Podcast - Start Episode',
      eventProperties: {
        episode
      }
    }
  };
}

export function PODCAST_CLOSE_PLAYER({ episode }) {
  return {
    url: '/analytics/event',
    method: 'post',
    data: {
      eventName: 'Podcast - Close Player',
      eventProperties: {
        episode
      }
    }
  };
}

export function USER_PAYMENT_HISTORY({ year }) {
  return {
    url: `/users/payment-history?year=${year}`,
    method: 'get'
  };
}
