import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import AboutBoxSlice from '~components/AboutBoxSlice';

import OurMissionImage from './images/our-mission-image.png';
import NonProfitImage from './images/non-profit-image.png';
import WorldImage from './images/world-image.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  container: {
    width: '95%',
    margin: 'auto',
    padding: '20px'
  },
  aboutUsInfoSection: {
    margin: 'auto'
  },
  infoParagraph: {
    color: '#403C44'
  },
  subheading: {
    fontSize: '24px',
    fontWeight: '700',
    marginTop: '10px',
    marginBottom: '30px'
  },
  paragraph: {
    fontSize: '16px',
    fontWeight: '400'
  },
  media: {
    maxHeight: '70px',
    marginTop: '20px'
  }
}));

const SECTION_CONTENT = [
  {
    title: 'Our Mission',
    description:
      'Aleph Beta’s mission is to create relevant Jewish learning, to help people achieve answers that are meaningful and satisfying for their everyday life. Our Torah videos and guides help you look at some of life’s biggest questions, by taking you on a deep journey through the text of the Torah. We believe Torah study should be evidence-based, intellectually stimulating, and emotionally gripping.',
    image: OurMissionImage
  },
  {
    title: `We're a Nonprofit`,
    description:
      'Aleph Beta is a nonprofit organization, funded with the help of dedicated supporters. For a membership fee of $9 a month, more than 7,000 members help us bring the Torah to life. Together with our world-renowned educators, Aleph Beta is building an online library to make the amazing messages in the Torah available to students all over the world.',
    image: NonProfitImage
  },
  {
    title: 'We Bring Torah to Life',
    description:
      'Through animated Torah Videos and a multisensory experience, Aleph Beta helps bring Torah to life. The introspective content appeals to sophisticated audiences, but the animated style works to engage even young or beginner audiences. Our students should decide for themselves what they believe is compelling, and in this way, we strive to keep our Torah study honest and compelling.',
    image: WorldImage
  }
];

const WeeklyTorahPortionParshaStudy = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <AboutBoxSlice />
      <Container className={classes.container}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={2}
          className={classes.aboutUsInfoSection}
        >
          {SECTION_CONTENT &&
            SECTION_CONTENT.map(section => (
              <Grid
                key={section.title}
                item
                container
                direction="column"
                alignItems="flex-start"
                md={3}
                sm={5}
                xs={12}
                className={classes.infoParagraph}
              >
                <Grid item>
                  <img src={section.image} alt={section.title} className={classes.media} />
                </Grid>
                <Grid item>
                  <Typography className={classes.subheading}>{section.title}</Typography>
                  <Typography className={classes.paragraph}>{section.description}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default WeeklyTorahPortionParshaStudy;
