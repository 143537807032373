import React, { useReducer, useContext, useMemo, useEffect } from 'react';
import localStorage from 'store2';
import useConversionFramework from '~hooks/useConversionFramework';
import { isUserAuthTokenAvailable } from '~utils/request';
import userSessionReducer from './userSessionReducer';

const LOCAL_STORAGE_KEY = 'user-session-cache';

const getInitialSessionState = () => {
  const cachedUserSessionState = localStorage.get(LOCAL_STORAGE_KEY);
  if (cachedUserSessionState) {
    return {
      ...cachedUserSessionState,
      isUserStateLoading: true,
      jwtAvailable: isUserAuthTokenAvailable()
    };
  }

  return {
    user: {},
    isUserStateLoading: true,
    /*
     * jwtAvailable attribute is to be used as a pre-courser to any login attempt code.
     * In that sense, if jwtAvailable is false we can assume this is a guestUser and render accordingly.
     */
    jwtAvailable: isUserAuthTokenAvailable(),
    tishaBavHomeHeroFeaturedUrl: '',
    playlistPositions: {}
  };
};

const initialSessionState = getInitialSessionState();

const UserContext = React.createContext({
  session: initialSessionState,
  dispatch: () => {}
});

function useUserContext() {
  const context = useContext(UserContext);
  const { isMarketingSite } = useConversionFramework();

  if (!context) {
    throw new Error(`useUserContext must be used within a UserContextProvider component`);
  }

  if (isMarketingSite) {
    // for the marketing site, the only user session data needed is the jwtAvailable variable
    return {
      ...context,
      session: {
        user: {},
        jwtAvailable: context?.session?.jwtAvailable,
        isUserStateLoading: !!context?.session?.isUserStateLoading,
        marketingUser: {
          id: context?.session?.user?.id,
          accessLevel: context?.session?.user?.access_level,
          isSubscriber: context?.session?.user?.access_level > 1, // subscribed user
          isTemporary: !!context?.session?.user?.is_temporary, // temporary user
          isGuestUser: !context?.session?.jwtAvailable || !!context?.session?.user?.is_temporary, // any non registered user
          usedFreeTrial: !!context?.session?.user?.used_free_trial
        }
      }
    };
  }
  return context;
}

function UserContextProvider(props) {
  const [session, dispatch] = useReducer(userSessionReducer, initialSessionState);

  const value = useMemo(
    () => ({
      session,
      dispatch
    }),
    [session]
  );

  useEffect(() => {
    localStorage.set(LOCAL_STORAGE_KEY, value.session);
  }, [value]);

  return <UserContext.Provider value={value} {...props} />;
}

export { UserContextProvider, useUserContext, UserContext };
