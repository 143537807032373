import { isMobile } from 'react-device-detect';
import mixpanel from 'mixpanel-browser';
import VWOMixpanelPlugin from 'vwo-mixpanel-integration';
import { setCustomRequestHeader } from '~utils/request';
import { FEATURE_FLAGS, isFeatureEnabled } from '~utils/feature-helpers';

const isVwoIntegrationFeatureEnabled = isFeatureEnabled(FEATURE_FLAGS.VWO_INTEGRATION);

// Since it takes time to get the users access level from the backend and at the same time
// the user and different components might want to track events, we allow calling the functions
// before mixpanel is initialized. We store the functions and their arguments in an array
// and execute them once mixpanel is initialized
let isInitialized = false;
const delayedCalls = [];

const executeDelayedFunctions = () => {
  delayedCalls.forEach(({ fn, args }) => {
    fn(...args);
  });
  delayedCalls.length = 0;
};

// Higher order function to ensure that mixpanel is initialized before calling the function
// if mixpanel is not initialized, it will delay the function call until mixpanel is initialized
const ensureInitialized =
  fn =>
  (...args) => {
    if (!isInitialized) {
      // If not initialized yet, store the function and arguments into the array
      delayedCalls.push({ fn, args });
      // Prevent from being called right now
      return;
    }
    fn(...args);
  };

export const MIXPANEL_DEVICE_ID_HEADER = 'X-AB-MP-Device-Id';
export const MIXPANEL_PLATFORM_VARIANT_HEADER = 'X-AB-MP-Platform-Variant';

const setMixpanelGlobalEventProperties = ({ isGuestUser, accessLevel }) => {
  if (isGuestUser) {
    mixpanel.register({
      'Access Level': 0,
      'User Status': 'Guest'
    });
  } else if (!accessLevel || accessLevel < 2) {
    mixpanel.register({
      'Access Level': accessLevel,
      'User Status': 'Free'
    });
  } else {
    mixpanel.register({
      'Access Level': accessLevel,
      'User Status': 'Paid'
    });
  }
};

export const setMixpanelUserProfile = ({ id, isGuestUser }) => {
  // Mixpanel mandates that identity should not be provided for anonymous users
  const isAnonymousUser = !id || isGuestUser;
  if (isAnonymousUser) {
    return;
  }

  const mixpanelCompatibleId = String(id);
  mixpanel.identify(mixpanelCompatibleId);
};

export const initializeMixpanel = () => {
  mixpanel.init(process.env.GATSBY_MIXPANEL_API_TOKEN, {
    track_links_timeout: 1000,
    loaded: mp => {
      // set the mixpanel device id as a custom header
      setCustomRequestHeader(MIXPANEL_DEVICE_ID_HEADER, mp.get_property('$device_id'));
      setCustomRequestHeader(MIXPANEL_PLATFORM_VARIANT_HEADER, 'Marketing');
    }
  });

  mixpanel.register({
    Platform: 'Web',
    'Platform Variant': 'Marketing',
    'Platform Device': isMobile ? 'web_mobile' : 'web'
  });

  // VWO integration with mixpanel
  if (isVwoIntegrationFeatureEnabled) {
    VWOMixpanelPlugin(mixpanel);
  }
};

export const startSendingEventsToMixpanel = marketingUser => {
  setMixpanelGlobalEventProperties(marketingUser);

  setMixpanelUserProfile(marketingUser);

  isInitialized = true;
  // Execute the functions that were stored while mixpanel was not initialized
  executeDelayedFunctions();
};

export const trackMixpanelPageView = ensureInitialized(isEntryPage => {
  mixpanel.track_pageview({ 'Full Page Load': !!isEntryPage });
});

export const trackMixpanelEvent = ensureInitialized((eventName, properties = null) => {
  if (properties) {
    mixpanel.track(eventName, properties);
  } else {
    mixpanel.track(eventName);
  }
});

export const trackMixpanelEventWithCallback = ensureInitialized(
  (eventName, properties = null, callback = null) => {
    mixpanel.track(eventName, properties, { send_immediately: true }, callback);
  }
);

export const trackMixpanelLinks = ensureInitialized((query, eventName, properties = null) => {
  mixpanel.track_links(query, eventName, properties);
});

export const startMixpanelExperiment = ensureInitialized((experimentName, experimentVariant) => {
  mixpanel.track('$experiment_started', {
    'Experiment name': experimentName,
    'Variant name': experimentVariant
  });
});

export const resetMixpanel = () => mixpanel.reset();
