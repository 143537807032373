/* eslint-disable react/prop-types */
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  boolFlagFromString,
  calcDurationOfFirstVideoDisplayedShort
} from '~utils/playlist-helpers';
import Link from '~components/Link';

import Tooltip from '@material-ui/core/Tooltip';
import WhiteCheckbox from '~images/other/white-checkbox.svg';
import { useTestId } from '~utils/set-testid';
import { LabelOverlay, LabelNew, LabelPremium, LabelAudio, LabelProducer } from '../PlaylistLabels';
import { customImageSize } from '../../../utils/image-sizes';

const useStyles = makeStyles(theme => ({
  card: {
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.15)',
    minHeight: '250px',
    '&:hover': {
      transform: 'scale(1.04)',
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.12)'
    }
  },
  cardActionsPaddingFix: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  cardContentHeight: {
    height: '82px',
    position: 'relative'
  },
  media: {
    width: '100%'
  },
  mediaCard: {
    position: 'relative',
    minHeight: '165px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props => props.mediaCardBackgroundColor || 'transparent'
  },
  isWatchedIndicator: {
    display: 'block',
    position: 'absolute',
    top: '8px',
    right: '12px',
    width: '32px',
    height: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '32px'
  },
  isWatchedIndicatorTooltip: {
    color: 'white',
    fontSize: '12px',
    lineHeight: '14.52px'
  },
  progressLine: {
    width: '100%'
  }
}));

const PlaylistCardFoundation = memo(
  ({
    title,
    author,
    cover_image,
    cover_color,
    label_is_audio,
    label_is_new,
    label_is_premium,
    label_is_producer,
    videos,
    progress
  }) => {
    const isAudio = boolFlagFromString(label_is_audio);
    const isNew = boolFlagFromString(label_is_new);
    const isPremium = boolFlagFromString(label_is_premium);
    const isProducer = boolFlagFromString(label_is_producer);

    const duration =
      videos &&
      videos.length > 0 &&
      calcDurationOfFirstVideoDisplayedShort(videos[0].length_in_seconds);
    const styleProps = useMemo(() => ({ mediaCardBackgroundColor: cover_color }), [cover_color]);
    const classes = useStyles(styleProps);

    const hasMultipleVideos = videos && videos.length > 1;

    const isWatched = progress.progress > 95;

    return (
      <Card className={classes.card}>
        <CardMedia className={classes.mediaCard}>
          {isWatched && (
            <Tooltip
              title={
                <div className={classes.isWatchedIndicatorTooltip}>
                  You’ve completed this series.
                </div>
              }
              arrow
              placement="bottom"
            >
              <span className={classes.isWatchedIndicator}>
                <img src={WhiteCheckbox} width={32} height={32} alt="yes" />
              </span>
            </Tooltip>
          )}
          {cover_image?.localFile && (
            <img src={cover_image?.url} alt={title || ''} className={`${classes.media} lazyload`} />
          )}

          {!cover_image?.localFile && cover_image?.url && (
            <Img
              src={customImageSize(cover_image?.url, 292, 165)}
              alt={cover_image?.alt || title}
              className={`${classes.media} lazyload`}
              width={292}
              height={164}
            />
          )}

          {!!progress.progress && (
            <LinearProgress
              variant="determinate"
              value={progress.progress}
              className={classes.progressLine}
            />
          )}
        </CardMedia>
        <CardContent className={classes.cardContentHeight}>
          <Typography variant="subtitle2" component="h3" gutterBottom>
            {title}
          </Typography>
          <LabelOverlay>
            {isNew && <LabelNew />}
            {isAudio && <LabelAudio />}
            {isPremium && <LabelPremium />}
            {isProducer && <LabelProducer />}
          </LabelOverlay>
        </CardContent>
        <CardActions className={classes.cardActionsPaddingFix}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="caption">{author}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{duration}</Typography>
              {hasMultipleVideos && <Typography variant="caption"> &#x25CF; Part 1</Typography>}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }
);

PlaylistCardFoundation.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  cover_image: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  cover_color: PropTypes.string.isRequired,
  label_is_audio: PropTypes.string,
  label_is_new: PropTypes.string,
  label_is_premium: PropTypes.string,
  label_is_producer: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      length_in_seconds: PropTypes.number.isRequired
    })
  ).isRequired,
  progress: PropTypes.shape({
    last_video_sequence_number: PropTypes.number,
    last_video_position: PropTypes.number,
    last_video_id: PropTypes.string,
    progress: PropTypes.number
  }).isRequired
};
PlaylistCardFoundation.defaultProps = {
  label_is_audio: false,
  label_is_new: false,
  label_is_premium: false,
  label_is_producer: false
};

// Note: This is a higher order component that will return the card with a link with it if visible
// This is done to because if the card is not visible it has aria-visible='hidden', but also has
// an a tag down the the tree which causes low score of Accessability on lighthouse.

const PlaylistCard = memo(
  ({
    url,
    progress,
    title,
    author,
    cover_image,
    cover_color,
    label_is_audio,
    label_is_new,
    label_is_premium,
    label_is_producer,
    videos
  }) => {
    const lastVideoInfo =
      progress && progress.last_video_id
        ? { index: progress.last_video_sequence_number, seekToSecond: progress.last_video_position }
        : {};

    const testid = useTestId('playlist-item');

    return (
      <Link to={url} style={{ textDecoration: 'none' }} state={lastVideoInfo} {...testid}>
        <PlaylistCardFoundation
          progress={progress}
          title={title}
          author={author}
          cover_image={cover_image}
          cover_color={cover_color}
          label_is_audio={label_is_audio}
          label_is_new={label_is_new}
          label_is_premium={label_is_premium}
          label_is_producer={label_is_producer}
          videos={videos}
        />
      </Link>
    );
  }
);

PlaylistCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  cover_image: PropTypes.shape({
    fluid: PropTypes.string.isRequired
  }).isRequired,
  cover_color: PropTypes.string,
  label_is_audio: PropTypes.string,
  label_is_new: PropTypes.string,
  label_is_premium: PropTypes.string,
  label_is_producer: PropTypes.string,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      length_in_seconds: PropTypes.number.isRequired
    })
  ).isRequired,
  progress: PropTypes.shape({
    last_video_sequence_number: PropTypes.number,
    last_video_position: PropTypes.number,
    last_video_id: PropTypes.string,
    progress: PropTypes.number
  })
};

PlaylistCard.defaultProps = {
  label_is_audio: false,
  label_is_new: false,
  label_is_premium: false,
  label_is_producer: false,
  progress: { progress: 0 },
  cover_color: 'transparent'
};

export default PlaylistCard;
